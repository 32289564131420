

body{color: #fff; background-color: #708d94; background-image: url(./../images/gen-tree-dk.png); background-position:  0% 30px; background-repeat: no-repeat; font-family: 'mr-eaves-modern', sans-serif;}

h1, h2, h3, h4, h5, h6{font-family: montserrat, sans-serif; font-weight: 100;;}
p, li{font-size: 1.275rem; }

a:link, a:visited{color: #fff;}
h2{font-size: 2.5rem; }

#home-intro{ margin-top: 175px; margin-left: 280px; min-height: 320px;;
    h1{font-size: 7rem; margin-bottom: 0;;}
    p.tagline{font-size: 2.125rem; margin-left: 2rem; font-weight: 300; margin-top: -20px;}
}

#about{background: url('./../images/stripes-2.png') 95% 50% no-repeat;}
#background{background: url('./../images/stripes.png') 5% 50% no-repeat;}